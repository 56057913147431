.group-card {
  width: 350px;
  flex-shrink: 0;
  .group-header {
    gap: 10px;
    .heading {
      word-break: break-word;
      .desc {
        margin-bottom: 0;
        font-size: 12px;
      }
    }
  }
  .dropbox {
    min-height: 400px;
  }
}

.active {
  background-color: #e2e2e2;
  transition: all 0.2s ease-in-out;
}
