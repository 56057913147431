.task-card {
  background-color: #ced4da;
  cursor: move;
  .task-head {
    background-color: #bbc1c7;
    h6 {
      word-break: break-word;
    }
    .buttons {
      flex-shrink: 0;
    }
  }
  .close {
    cursor: pointer;
  }
}
